import React from 'react'
import { Dialog, Box, Image } from 'pcln-design-system'
import CONTENT from '@/constants/content'
import StyledHeading from '@/components/StyledHeading'
import Form from './SearchForm/Form'
import { FLIGHT_TYPE_INFO, ROUND_TRIP, ONE_WAY } from './constants'
import { onSubmitExpressDeal } from './SearchForm/utils'

const expressDealLogo =
  'https://s1.pclncdn.com/design-assets/brand-assets/express-deals-logo-registard.svg'

export default function ExpressDealDialog({
  isOpen,
  closeDialog,
  isMobile
}: {
  isMobile?: boolean
  isOpen: boolean
  closeDialog: () => void
}) {
  const mobileProps = {
    sheet: true,
    fullWidth: true,
    headerShowCloseButton: true,
    headerContent: '  ',
    showCloseButton: false
  } as const
  const dialogProps = isMobile
    ? mobileProps
    : ({ overflowY: 'visible', overflowX: 'visible' } as const)
  return (
    <Dialog
      open={isOpen}
      ariaDescription="Search for Express Deals"
      ariaTitle="Flights Express Deal Dialog"
      borderRadius="2xl"
      onOpenChange={closeDialog}
      scrimColor="dark"
      innerContentOverflow="visible"
      scrimDismiss
      size={isMobile ? 'full' : 'md'}
      {...dialogProps}
    >
      <Box pt={[null, null, null, 3]} pb={2} px={3}>
        <Image src={expressDealLogo} height="24px" width="164px" />
        <StyledHeading
          text={CONTENT.homepage.flights.expressDeals.headline}
          fontSize={[3, null, null, 4]}
          lineHeight="display"
          tag="h2"
        />
        <Box pt={2}>
          <Form
            onSubmit={onSubmitExpressDeal}
            promoDeal
            flightTypes={[
              FLIGHT_TYPE_INFO[ROUND_TRIP],
              FLIGHT_TYPE_INFO[ONE_WAY]
            ]}
          />
        </Box>
      </Box>
    </Dialog>
  )
}
