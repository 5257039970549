export const abandonedHotelQuery = `
          ... on AbandonedHotel {
              isBooked
              hotelURL:url
              genericListingsUrl
              checkInDate
              checkOutDate
              numberOfAdults
              numberOfRooms
              pricePerNightAtAbandonment
              dealType
              productType
              hotelInfo {
              ... on RtlHotel {
                  __typename
                  thumbnail {
                      source
                  }
                  name
                  starRating
                  reviewSummaryWithEntityInput {
                      totalReviews
                      scoreBreakdown {
                          overall
                      }
                  }
                  neighborhood {
                      name
                  }
              }
              ... on SopqHotel {
                  __typename
                  name
                  starRating
                  neighborhood {
                    name
                  }
                  location {
                    name
                  }
                }
              ... on AbandonedPriceBreakersHotel {
                  __typename
                  pricebreakerDescription
                  pricebreakerLocation
              }
              }
          }
      `

export const abandonedRentalCarQuery = `
          ... on AbandonedRentalCar {
              isBooked
              carURL: url
              genericListingsUrl
              averageRating
              carExample
              carType
              imageURL: imageUrl
              isDropoffAtDifferentLocation
              numberOfReviews
              passengerCapacity
              pickupDateTime
              pickupLocation
              retailTypeCarCompanyDetails {
                brandImageURL: brandImageUrl
              }
              returnDateTime
              returnLocation
            }
      `
