import { useRouter } from 'next/router'
import { omit } from 'ramda'

export default function useExpressDealDialog(
  qsKey = 'modal',
  qsValue = 'express-deals'
) {
  const router = useRouter()
  const { query, pathname } = router
  const isOpen = query[qsKey] === qsValue
  const openDialog = () => {
    void router.push(
      {
        pathname,
        query: { ...query, [qsKey]: qsValue }
      },
      undefined,
      { shallow: true }
    )
  }
  const closeDialog = () => {
    void router.push(
      {
        pathname,
        query: omit([qsKey], query)
      },
      undefined,
      { shallow: true }
    )
  }
  return { isOpen, openDialog, closeDialog }
}
