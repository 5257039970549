import React, { useEffect } from 'react'
import { useMediaQuery } from 'react-responsive'
import { Box, Text, Button, Flex, Heading, theme } from 'pcln-design-system'
import { Carousel } from 'pcln-carousel'
import { ActionCard } from '@pcln/cards'
import AbandonedSelection from '@/components/TripActivity/AbandonedSelection'
import useSeti from '@/hooks/useSeti'
import { type SearchProductList } from '@/hooks/useRecentSearchCard'
import { RecentSearchCard } from '@pcln/trip-activity-components'
import useBootstrapData from '@/hooks/useBootstrapData'
import getProductInfo, {
  getPriceWatchProps,
  getRecentSearchDisplayProps
} from './utils'
import useTripActivityData from './hooks/useTripActivityData'
import StyledWrapper from './TripActivity.styled'
import {
  RecentTripSearches,
  SearchProductDataFiltered,
  AbandonedSelection as AbandonedSelectionType,
  PriceWatchData,
  PriceWatchDataWithFlags
} from './types'
import fireRecentSearchCardClickEvent, {
  fireRecentSearchLoadEvent,
  fireViewAllButtonClickEvent
} from './ga4'
import ConditionalRender, { Original, Experiment } from '../ConditionalRender'

function onCardClick(
  item:
    | SearchProductDataFiltered
    | RecentTripSearches
    | PriceWatchDataWithFlags,
  relativeUrl: string,
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME',
  productType: 'FLY' | 'STAY' | 'DRIVE'
) {
  fireRecentSearchCardClickEvent(item, productType, analyticsCategory)
  const url = new URL(relativeUrl, window.location.origin)
  window.open(url)
}

function onViewAllButtonClick(analyticsCategory: string) {
  fireViewAllButtonClickEvent(analyticsCategory)
  window.open('/next-profile/trips?tab=recent_activity')
}

export default function TripActivity({
  productType,
  analyticsCategory,
  setIsVisible,
  shouldUseSmallerHeading,
  abandonedSelectionData,
  hideAbandonedSelection,
  isTripGroupingExperiment
}: {
  setIsVisible?: React.Dispatch<React.SetStateAction<boolean>>
  productType: SearchProductList
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME'
  shouldUseSmallerHeading?: boolean
  abandonedSelectionData?: AbandonedSelectionType | null
  hideAbandonedSelection?: boolean
  isTripGroupingExperiment?: boolean
}) {
  const {
    signInStatus: { signedIn }
  } = useBootstrapData()
  const isDesktop = useMediaQuery({
    query: `(min-width: ${theme.breakpoints.lg as string})`
  })
  const isNewRecentSearchQuery = useSeti('HP_RT_SEARCHES', false) === 'VARIANT'
  const isShowPriceWatch = useSeti('HP_PUWYLO_PRICEWATCH', false) === 'VARIANT'
  const {
    atMostFiveRecentSearchCards,
    abandonedSelection,
    atMostFiveRecentSearchCardsExperiment = []
  } = useTripActivityData(
    productType,
    abandonedSelectionData,
    isTripGroupingExperiment
  )
  const atMostFiveRecentSearchCardsSeti = isShowPriceWatch
    ? atMostFiveRecentSearchCardsExperiment
    : atMostFiveRecentSearchCards
  const hasRecentSearchCards = atMostFiveRecentSearchCardsSeti.length > 0
  useEffect(() => {
    if (setIsVisible) {
      setIsVisible(hasRecentSearchCards || abandonedSelection !== null)
    }
  }, [hasRecentSearchCards, abandonedSelection, setIsVisible])

  useEffect(() => {
    if (atMostFiveRecentSearchCardsSeti.length > 0) {
      fireRecentSearchLoadEvent(
        atMostFiveRecentSearchCardsSeti,
        analyticsCategory
      )
    }
  }, [atMostFiveRecentSearchCardsSeti, analyticsCategory])

  return hasRecentSearchCards ||
    (abandonedSelection && !hideAbandonedSelection) ? (
    <StyledWrapper borderRadius="2xl" bg="priceSecondary.light">
      <Box>
        <Flex justifyContent="space-between" alignItems="flex-start">
          <Heading
            mt={0}
            mb={3}
            ml={[1, null, null, 0]}
            textStyle={
              shouldUseSmallerHeading
                ? ['heading4', 'heading3']
                : ['heading3', 'heading2']
            }
            color="text.heading"
          >
            Pick up where you left off
          </Heading>
          {isDesktop && (
            <Button
              variation="white"
              data-testid="view-all-button-top-right"
              onClick={() => onViewAllButtonClick(analyticsCategory)}
              {...(shouldUseSmallerHeading && { size: 'small' })}
            >
              View All Recent Activity
            </Button>
          )}
        </Flex>
        {abandonedSelection && !hideAbandonedSelection && (
          <>
            <Box mb={2}>
              <AbandonedSelection
                abandonedSelection={abandonedSelection}
                analyticsCategory={analyticsCategory}
              />
            </Box>
            {hasRecentSearchCards && (
              <Text
                textStyle="heading4"
                color="text.heading"
                pt={3}
                pb={[2, null, 3]}
              >
                Or continue browsing
              </Text>
            )}
          </>
        )}
        {hasRecentSearchCards && (
          <ConditionalRender
            experimentCode="HP_PUWYLO_PRICEWATCH"
            triggerExperiment={() => {
              return signedIn
            }}
          >
            <Original>
              <Carousel
                arrowsPositions="top"
                arrowsPosition="side"
                currentSlide={0}
                displayArrowsMobile={false}
                slideSpacing={1}
                onSlideChange={() => {
                  // do  nothing
                }}
                visibleSlides={[2.5, 2.5, 3.5]}
                mobileVisibleSlides={[1.2, 2.5]}
              >
                {atMostFiveRecentSearchCards.map((item, index) => {
                  const { overline, heading, icon, actionText, relativeUrl } =
                    isNewRecentSearchQuery
                      ? getRecentSearchDisplayProps(item as RecentTripSearches)
                      : getProductInfo(item as SearchProductDataFiltered)
                  return (
                    <ActionCard
                      key={overline || index}
                      headingWidth={['224px', null, null, '250px']}
                      icon={icon}
                      heading={heading}
                      actionText={actionText}
                      overline={overline}
                      onClick={() => {
                        onCardClick(
                          item,
                          relativeUrl,
                          analyticsCategory,
                          item.productType
                        )
                      }}
                      handleSubmitCTA={() => {
                        onCardClick(
                          item,
                          relativeUrl,
                          analyticsCategory,
                          item.productType
                        )
                      }}
                      className="full-width"
                    />
                  )
                })}
              </Carousel>
            </Original>
            <Experiment>
              <Carousel
                arrowsPositions="top"
                arrowsPosition="side"
                currentSlide={0}
                displayArrowsMobile={false}
                slideSpacing={1}
                onSlideChange={() => {
                  // do  nothing
                }}
                visibleSlides={[2.5, 2.5, 3.5]}
                mobileVisibleSlides={[1.2, 2.5]}
              >
                {atMostFiveRecentSearchCardsExperiment.map((item, index) => {
                  if (item.isPriceWatch) {
                    const {
                      heading,
                      icon,
                      actionText,
                      relativeUrl,
                      priceWatch
                    } = getPriceWatchProps(item as PriceWatchData)
                    return (
                      <RecentSearchCard
                        isPriceWatch
                        priceWatch={priceWatch}
                        key={heading || index}
                        headingWidth={['224px', null, null, '250px']}
                        icon={icon}
                        heading={heading}
                        actionText={actionText}
                        overline="Price Watch"
                        onClick={() => {
                          onCardClick(
                            item,
                            relativeUrl,
                            analyticsCategory,
                            item.productType
                          )
                        }}
                        handleSubmitCTA={() => {
                          onCardClick(
                            item,
                            relativeUrl,
                            analyticsCategory,
                            item.productType
                          )
                        }}
                        className="full-width"
                        isIconRounded
                      />
                    )
                  }

                  const { overline, heading, icon, actionText, relativeUrl } =
                    isNewRecentSearchQuery
                      ? getRecentSearchDisplayProps(item as RecentTripSearches)
                      : getProductInfo(item as SearchProductDataFiltered)
                  return (
                    <RecentSearchCard
                      key={overline || index}
                      headingWidth={['224px', null, null, '250px']}
                      icon={icon}
                      heading={heading}
                      actionText={actionText}
                      overline={overline}
                      onClick={() => {
                        onCardClick(
                          item,
                          relativeUrl,
                          analyticsCategory,
                          item.productType
                        )
                      }}
                      handleSubmitCTA={() => {
                        onCardClick(
                          item,
                          relativeUrl,
                          analyticsCategory,
                          item.productType
                        )
                      }}
                      className="full-width"
                      isIconRounded
                    />
                  )
                })}
              </Carousel>
            </Experiment>
          </ConditionalRender>
        )}

        {!isDesktop && (
          <Box pt={24}>
            <Button
              variation="white"
              width={1}
              data-testid="view-all-button-bottom"
              onClick={() => onViewAllButtonClick(analyticsCategory)}
              {...(shouldUseSmallerHeading && { size: 'small' })}
            >
              View All Recent Activity
            </Button>
          </Box>
        )}
      </Box>
    </StyledWrapper>
  ) : null
}
