export const PRODUCT_TABS = [
  {
    autobotID: 'DASH_TAB_HOTELS',
    name: 'HOTELS',
    tabText: 'Hotels',
    id: 'hotels'
  },
  {
    autobotID: 'DASH_TAB_FLIGHTS',
    name: 'FLIGHTS',
    tabText: 'Flights',
    id: 'flights'
  },
  {
    autobotID: 'DASH_TAB_HOTEL + FLIGHT',
    name: 'BUNDLE + SAVE',
    tabText: 'Bundle & Save',
    id: 'vacations'
  },
  {
    autobotID: 'DASH_TAB_CARS',
    name: 'CARS',
    tabText: 'Cars',
    id: 'cars'
  },
  {
    autobotID: 'DASH_TAB_CRUISES',
    name: 'CRUISES',
    tabText: 'Cruises',
    id: 'cruises'
  }
] as const

export const HEADERS = {
  hotels: {
    headline: 'Save more on your next hotel',
    mobileHeadline: 'Save more on your next hotel'
  },
  flights: {
    headline: 'Save more on your next flight',
    mobileHeadline: 'Save more on your next flight'
  },
  vacations: {
    headline: 'Save up to $625 on your hotel and flight',
    mobileHeadline: 'Save up to $625 on your hotel and flight'
  },
  cars: {
    headline: 'Save more on your next rental car',
    mobileHeadline: 'Save more on your next rental car'
  },
  cruises: {
    headline: 'Save more on your next cruise',
    mobileHeadline: 'Save more on your next cruise'
  }
}

export const AMENITIES = {
  AIRSHUTTL: 'Search Hotels with Airport Shuttles',
  CASINO: 'Search Hotels with Casinos',
  FBRKFST: 'Search Hotels with Free Breakfast',
  FINTRNT: 'Search Hotels with Free Internet',
  FITSPA: 'Search Hotels with Gyms',
  FPRKING: 'Search Hotels with Free Parking',
  INCLUSIVE: 'Search All Inclusive Hotels',
  KITCHEN: 'Search Hotels with Kitchens',
  PETALLOW: 'Search Pet Friendly Hotels',
  SPOOL: 'Search Hotels with Swimming Pools',
  RESTRNT: 'Search Hotels with Restaurants',
  SPA: 'Search Hotels with Spas'
} as const
