import React from 'react'
import styled, { keyframes } from 'styled-components'
import { Box, Flex, Text, Image } from 'pcln-design-system'

const rotateText = keyframes`
  0% { opacity: 0; }
  50% { opacity: 1; }
  51% { opacity: 0; }
  100% { opacity: 0; }
`

const FlexWrapper = styled(Flex)`
  height: 100%;
  position: relative;
`
const PrimaryBanner = styled(Text)`
  animation: ${rotateText} 8s infinite;
`

const SecondaryBanner = styled(Flex)`
  animation: ${rotateText} 8s 4s infinite;
  bottom: 0;
  left: 0;
  opacity: 0;
  position: absolute;
  right: 0;
  top: 0;
`

export default function RotatingBanner({
  image,
  primaryBannerText,
  secondaryBannerText,
  altText,
  ...props
}: {
  height: string
  width: string
  image: string
  primaryBannerText: string
  secondaryBannerText: string
  altText: string
}) {
  return (
    <FlexWrapper
      width={1}
      alignItems="center"
      flexDirection="column"
      justifyContent="center"
    >
      <PrimaryBanner color="primary" fontWeight="normal">
        {primaryBannerText}
        <sup>*</sup>
      </PrimaryBanner>
      <SecondaryBanner alignItems="center" justifyContent="center">
        <Text color="primary" fontWeight="normal">
          {secondaryBannerText}
        </Text>
        {image && (
          <Box mx={3} width="310px">
            <Image
              src={`${image}?opto&auto=avif,webp`}
              alt={altText}
              loading="lazy"
              {...props}
            />
          </Box>
        )}
      </SecondaryBanner>
    </FlexWrapper>
  )
}
