import { useRouter } from 'next/router'
import { AMENITIES } from '@/components/SearchForm/constants'

export default function useSEMSearchHeadline() {
  const { query } = useRouter()
  const { SEMSearch, SEMSEARCH } = query
  const semSearchParam = (
    (SEMSearch || SEMSEARCH) as string | undefined
  )?.toUpperCase() as keyof typeof AMENITIES
  return AMENITIES[semSearchParam]
}
