import React, { useEffect } from 'react'
import {
  TripActivity as RecentActivityHotel,
  RecentActivityRentalCar
} from '@pcln/marketing'
import {
  fireAbandonedSelectionClickEvent,
  fireAbandonedSelectionLoadEvent
} from './ga4'
import { type AbandonedSelection } from './types'

export default function AbandonedSelection({
  abandonedSelection,
  analyticsCategory,
  useSmallerView = false,
  isTripGroupedSelection = false
}: {
  abandonedSelection: AbandonedSelection
  analyticsCategory: 'PCLN_HOME' | 'HOTEL_HOME'
  useSmallerView?: boolean
  isTripGroupedSelection?: boolean
}) {
  useEffect(() => {
    if (abandonedSelection) {
      fireAbandonedSelectionLoadEvent(
        abandonedSelection,
        analyticsCategory,
        isTripGroupedSelection
      )
    }
  }, [abandonedSelection, analyticsCategory, isTripGroupedSelection])

  if (abandonedSelection.productType === 'cars') {
    return (
      <RecentActivityRentalCar
        {...abandonedSelection}
        subheadline={
          <>
            Pick up Location: {abandonedSelection.pickupLocation}
            {abandonedSelection.isDropOffAtDifferentLocation && (
              <>
                <br />
                Drop Off Location: {abandonedSelection.returnLocation}
              </>
            )}
          </>
        }
        onClick={() => {
          fireAbandonedSelectionClickEvent(
            abandonedSelection,
            analyticsCategory,
            isTripGroupedSelection
          )
        }}
        isSmallerView={useSmallerView}
        altText="Trip activity image"
        ariaLabel="trip activity button"
      />
    )
  }

  return (
    <RecentActivityHotel
      {...abandonedSelection}
      onClick={() => {
        fireAbandonedSelectionClickEvent(
          abandonedSelection,
          analyticsCategory,
          isTripGroupedSelection
        )
      }}
      isSmallerView={useSmallerView}
      altText="Trip activity image"
      ariaLabel="trip activity button"
    />
  )
}
