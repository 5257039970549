import fetchPriceWatches from '@/shared-utils/fetchPriceWatches'
import { useQuery } from '@tanstack/react-query'

export default function usePriceWatches(
  email: string,
  appName: string,
  appVersion: string,
  isPriceWatchVariant: boolean
) {
  const { data } = useQuery({
    queryKey: [email, appName, appVersion],
    queryFn: () => fetchPriceWatches(email, appName, appVersion),
    staleTime: Infinity,
    enabled: !!email && isPriceWatchVariant
  })
  return data ?? []
}
