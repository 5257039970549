import { useQuery } from '@tanstack/react-query'
import { parse } from 'date-fns/parse'
import { isToday } from 'date-fns/isToday'
import { isAfter } from 'date-fns/isAfter'
import analytics from '@/shared-utils/analytics'
import {
  AbandonedSelectionApiResponse,
  AbandonedHotelSelectionApiResponse,
  AbandonedCarSelectionApiResponse,
  AbandonedSelection
} from '@/components/TripActivity/types'

import { type SearchProductList } from '@/hooks/useRecentSearchCard'
import fetchAbandonedSelections from '@/shared-utils/fetchAbandonedSelections'
import {
  transformAbandonedHotelApiResponseToProps,
  transformAbandonedCarApiResponseToProps
} from '../utils'

function isTripDateValid(tripDate: string, formatString: string) {
  const parseTripDate = parse(tripDate, formatString, new Date())

  return isToday(parseTripDate) || isAfter(parseTripDate, new Date())
}

function isValidAbandonedHotelSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedHotelSelectionApiResponse {
  return (
    'hotelURL' in selection &&
    isTripDateValid(selection.checkInDate, 'yyyy-MM-dd') &&
    !selection.isBooked
  )
}

function isValidAbandonedCarSelection(
  selection: AbandonedSelectionApiResponse
): selection is AbandonedCarSelectionApiResponse {
  return (
    'carURL' in selection &&
    isTripDateValid(selection.pickupDateTime, "yyyy-MM-dd'T'HH:mm:ss") &&
    !selection.isBooked
  )
}

export function getFirstValidAbandonedSelection(
  selections: ReadonlyArray<AbandonedSelectionApiResponse>
) {
  const abandonedSelection =
    selections.find(selection => {
      if (isValidAbandonedHotelSelection(selection)) {
        return true
      }

      return isValidAbandonedCarSelection(selection)
    }) ?? null

  if (abandonedSelection) {
    return 'hotelURL' in abandonedSelection
      ? transformAbandonedHotelApiResponseToProps(abandonedSelection)
      : transformAbandonedCarApiResponseToProps(abandonedSelection)
  }

  return null
}

export default function useAbandonedSelections(
  cguid: string,
  appName: string,
  appVersion: string,
  signedIn: boolean,
  productType: SearchProductList,
  shouldSkipAbandonedSelections = false
) {
  const { data = null, error } = useQuery<AbandonedSelection | null>({
    queryKey: [cguid, appName, appVersion, signedIn, productType],
    queryFn: async () => {
      const selections = await fetchAbandonedSelections(
        cguid,
        appName,
        appVersion,
        signedIn,
        productType
      )

      return getFirstValidAbandonedSelection(selections)
    },
    staleTime: Infinity,
    enabled: !shouldSkipAbandonedSelections
  })

  if (error) {
    analytics.logError({
      message: 'Error thrown from useQuery',
      erorr: JSON.stringify(error),
      service: 'fetchAbandonedSelections'
    })
  }

  return data
}
