import React from 'react'

const GREETING_MOBILE = 'Welcome to Priceline'
export const GREETING_DESKTOP = 'Welcome to Priceline'

const Content = {
  homepage: {
    hotel: {
      signedInSubHeading: 'You qualify for VIP hotel deals! Search and save.'
    },
    flights: {
      expressDeals: {
        headline: 'Save up to 50% on trusted airlines!'
      }
    },
    vip: {
      defaultHeading: GREETING_MOBILE,
      promo: {
        headline:
          'You qualify for hotel discounts of up to 50% as a VIP member!',
        headlineWithName:
          '{firstName}, you qualify for hotel discounts of up to 50% as a VIP member!'
      }
    }
  }
} as const

export const vipHeading = {
  headlineWithName: (firstName: string) => {
    return (
      <>
        <span translate="no">{firstName}</span>
        <span>, you qualify for VIP deals</span>
      </>
    )
  }
}

export const expressDeals = {
  hotelsHeadline: () => {
    return (
      <>
        Search our lowest price hotels deals - <b>up to 60% off!</b>{' '}
      </>
    )
  }
} as const

export default Content
