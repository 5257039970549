import useRecentSearchCard, {
  SearchProductList
} from '@/hooks/useRecentSearchCard'
import useBootstrapData from '@/hooks/useBootstrapData'
import useSeti from '@/hooks/useSeti'
import usePriceWatches from '@/hooks/usePriceWatches'
import useAbandonedSelections from './useAbandonedSelections'
import type { AbandonedSelection } from '../types'
import { isPriceWatch } from '../utils'
import {
  PriceWatchDataWithFlags,
  RecentTripSearches,
  SearchProductDataFiltered
} from '../types'

function useTripActivityData(
  productType: SearchProductList,
  abandonedSelectionData?: AbandonedSelection | null,
  isTripGroupingExperiment = false
) {
  const {
    webstats: { clientGUID },
    appName,
    appVersion,
    signInStatus: { signedIn },
    customer: { emailAddress }
  } = useBootstrapData()

  const isNewRecentSearchQuery = useSeti('HP_RT_SEARCHES', false) === 'VARIANT'

  const isPriceWatchSeti = useSeti('HP_PUWYLO_PRICEWATCH', false) === 'VARIANT'

  const shouldSkipAbandonedSelections = !!isTripGroupingExperiment && signedIn

  const abandonedSelectionFetched = useAbandonedSelections(
    clientGUID,
    appName,
    appVersion,
    signedIn,
    productType,
    shouldSkipAbandonedSelections
  )

  // when HP_PUWYLO_TRIPGROUPING variant and signed in, abandonedSelectionData is passed in as a prop from the parent
  // shouldSkipAbandonedSelections will be false, and we will get the data from useTripGroupings
  const abandonedSelection = abandonedSelectionFetched || abandonedSelectionData

  const data = useRecentSearchCard(
    clientGUID,
    { appName, appVersion },
    productType,
    signedIn
  )

  const priceWatches = usePriceWatches(
    emailAddress,
    appName,
    appVersion,
    isPriceWatchSeti
  )
  const priceWatchesWithFlag: PriceWatchDataWithFlags[] = priceWatches.map(
    item => ({
      ...item,
      isPriceWatch: true,
      productType: 'FLY' as const
    })
  )
  type RecentSearches = SearchProductDataFiltered | RecentTripSearches
  type RecentSearchesWithFlag = RecentSearches & {
    isPriceWatch: boolean
  }

  const filteredRecentSearches = data.filter(item => {
    return !isPriceWatch(item, priceWatches, isNewRecentSearchQuery)
  })
  const recentSearchesWithFlag: RecentSearchesWithFlag[] =
    filteredRecentSearches.map(searches => ({
      ...searches,
      isPriceWatch: false
    }))

  const recentSearches = [...priceWatchesWithFlag, ...recentSearchesWithFlag]
  const atMostFiveRecentSearchCardsExperiment =
    recentSearches.length > 5 ? recentSearches.slice(0, 5) : recentSearches
  const atMostFiveRecentSearchCards = data.length > 5 ? data.slice(0, 5) : data

  return {
    abandonedSelection,
    atMostFiveRecentSearchCards,
    atMostFiveRecentSearchCardsExperiment
  }
}

export default useTripActivityData
